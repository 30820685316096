<template>
    <main-confirm @confirm="$emit('closeModal')" @cancel="$emit('closeModal')" confirm-title="checkout.transaction_error" :description="description" :oneOption="true">
        <icon-warning :size="60"/>
    </main-confirm>
</template>
<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue'
import IconWarning from '../../../../common/svg/iconWarning.vue'
export default {
    components: { MainConfirm, IconWarning},
    name: "Error",
    props:{
        description:{
            tyoe: String,
            default: 'checkout.description_transaction_error'
        }
    }
}
</script>

<template>
    <div>
        <form-wizard :stepSize="'xs'" :title="'Crear direccion'" :subtitle="'Solo se podra crear luego de completar estos pasos.'" :color="'#BC2C35'"
        ref="createAddressWizard">
            <tab-content title="Verificacion" :before-change="verifyCredential">
                <main-input :value="credential" :title="'Codigo del cliente'" :id="'credential'" :type="'text'" ref="credential" @changeValue="(data)=>{credential = data}" :placeholder="'Introduza el codigo'">
                    <icon-code/>
                </main-input>
                <sub>Introduzca el codigo del cliente al cual desea crearle una direccion para poder continuar.</sub>
            </tab-content>
            <tab-content title="Metodo" :before-change="verifyCredentialByEmail">
                <!-- <h4>Verifica tu entidad</h4> -->
                <main-input :value="email" :title="'Introduzca su email'" :id="'email'" :type="'text'" ref="email" @changeValue="(data)=>{email = data}" :placeholder="'Introduza el email'">
                    <icon-email/>
                </main-input>
                <h6 class="mt-2 size-14">
                    {{entity.email}}
                </h6>
                <sub>Introduzca el email del cliente para obtener el codigo de acceso o cambie de opcion.</sub>
                <div class="d-flex justify-content-between mt-3">
                    <a href="#" @click.prevent="nextWithMfa">MFA (Aplicacion movil)</a>
                </div>
            </tab-content>
            <tab-content title="Address" :before-change="addAddressFunction">
                <create-address :overflowHidden="false" :customer="true" :add="add" @addCustomerAddress="addCustomerAddress" :reset="resetAddress"/>
            </tab-content>
            <tab-content title="MFA" :before-change="addAddress">
                <div v-if="isMfa">
                    <main-input :value="mfa" :title="'Introduzca su mfa'" :id="'mfa'" :type="'text'" ref="mfa" @changeValue="(data)=>{mfa = data}" :placeholder="'Introduza el mfa'"
                    :mask="'####-####'"/>
                    <sub>Introduzca el token MFA propotcionado por la aplicacion movil.</sub>
                </div>
                <div v-else>
                    <main-input :value="otp" :title="'Introduzca el codigo'" :id="'code-email'" :type="'text'" ref="codeEmail" @changeValue="(data)=>{otp = data}" :placeholder="'Introduza el codigo'">
                        <icon-code/>
                    </main-input>
                    <sub>Introduzca el codigo enviado a su correo electronico.</sub>
                </div>
            </tab-content>
        </form-wizard>
        <error v-if="showError" :description="error" @closeModal="showError = false"/>
        <direction-not-allowed v-if="directionNotAllowed" @closeModal="directionNotAllowed = false"/>
    </div>
</template>

<script>
import MainInput from '../../../common/components/inputs/MainInput.vue'
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import CreateAddress from '../components/addresses/modals/CreateAddress.vue'
import { http } from "@/common/services/web-backend.js";
import DirectionNotAllowed from '../components/addresses/modals/DirectionNotAllowed.vue';
import Error from '../../checkout/components/modals/Error.vue';
import IconCode from '../../auth/assets/svg/iconCode.vue';
import IconEmail from '../../auth/assets/svg/iconEmail.vue';
import axios from 'axios'
    export default {
        components: { MainInput, FormWizard, TabContent, CreateAddress, DirectionNotAllowed, Error, IconCode, IconEmail },
        name: "CreateAddressPage",
        data(){
            return{
                credential: null,
                entity: {},
                type: 'code',
                email: null,
                mfa: null,
                otp: null,
                address: {},
                isMfa: false,
                directionNotAllowed: false,
                add: false,
                error: '',
                showError: false,
                validate: false,
                resetAddress: false
            }
        },
        methods:{
            nextWithMfa(){
                this.isMfa = true
                this.$refs.createAddressWizard.nextTab()
            },
            async verifyCredential() {
                this.$refs.credential.$v.$touch()
                if(this.$refs.credential.$v.$error){
                    return false
                }
                try {
                    const response = await http.post(`../verifications/credentials`, {
                        type: this.type,
                        credential: this.credential
                    })
                    this.entity = response.data.data
                    return true
                } catch (e) {
                    this.showError = true
                    this.error  = e.response.data.message
                }
                window.scroll(0, 0)
            },
            async verifyCredentialByEmail(){
                try {
                    if(this.isMfa){
                        return true
                    }else{
                        this.$refs.email.$v.$touch()
                        if(this.$refs.email.$v.$error){
                            return false
                        }
                        await http.post(`../verifications/credentialsByEmail`, {
                            code: this.credential,
                            email: this.email
                        })
                        window.scroll(0, 0)
                        return true
                    }
                } catch (e) {
                    this.showError = true
                    this.error  = e.response.data.message
                }
            },
            async addCustomerAddress(data){
                this.address = data.address
                console.log(data.address);
                try {
                    const address = await axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat='+data.address.latitud+'&lon='+data.address.longitud)
                    this.address.address_line = address.data.display_name
                } catch (error) {
                    console.log(error);
                }
                this.validate = data.validate
                if(this.validate){
                    setTimeout(() => {
                        this.$refs.createAddressWizard.nextTab()
                    }, 0);
                }
            },
            addAddressFunction(){
                this.add = !this.add
                window.scroll(0, 0)
                return this.validate
            },
            async addAddress(){
                if(this.isMfa){
                    this.$refs.mfa.$v.$touch()
                    if(this.$refs.mfa.$v.$error){
                        return false
                    }
                }else{
                    this.$refs.codeEmail.$v.$touch()
                    if(this.$refs.codeEmail.$v.$error){
                        return false
                    }
                }
                try {
                    await http.post(`../alter_entity/customers/${this.credential}/addresses`, {
                        ...this.address, 
                        mfa: this.isMfa ? parseInt(this.mfa.replace(/[(||)| |+|-]/g, "")) : null, 
                        otp: !this.isMfa ? this.otp : null,  
                        code:  this.credential, city_id: this.address.city.id
                    })
                    let notify = {
                        text: 'checkout.partner_upgraded_successfully',
                        type: 'success',
                        show: true,
                    }
                    this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                    this.reset()
                } catch (error) {
                    if(error.response.data.code == 406){
                        this.directionNotAllowed = true
                        return
                    }
                    this.showError = true
                    this.error = error.response.data.message
                }
            },
            reset(){
                this.credential = null
                this.email = null
                this.entity = {}
                this.mfa = null,
                this.address = {},
                this.isMfa = false
                this.resetAddress = !this.resetAddress
                setTimeout(() => {
                    this.$refs.credential.$v.$reset()
                }, 10);
                this.$refs.createAddressWizard.reset()
            }
        }
    }
</script>

<style scoped>
    .vue-form-wizard::v-deep .wizard-navigation ul li a .wizard-icon-circle i{
        font-style: normal !important;
        color: var(--main-navy);
    }
    .vue-form-wizard::v-deep .wizard-navigation ul li.active a .wizard-icon-circle i{
        color: #fff;
    }
    .vue-form-wizard::v-deep .wizard-navigation ul li.active a .wizard-icon-circle{
        background: var(--main-red);
    }
    .vue-form-wizard::v-deep .wizard-navigation > .wizard-tab-content{
        padding: 30px 20px 30px;
    }
    .modal-main-container::v-deep.create-address{
        position: relative;
        background: transparent !important;
        backdrop-filter: none !important;
        z-index: 1;
        max-height: none;
    }
    .modal-main-container::v-deep.create-address > .modal-main > div:first-child{
        display: none !important;
    }
    .modal-main-container::v-deep.create-address > .modal-main > .content{
        max-height: none;
    }
    .modal-main-container::v-deep.create-address > .modal-main{
        padding: 0;
    }
    .main-input::v-deep > .input-group {
        margin-bottom: 0px !important;
    }
</style>
